.assetsClassFiltersRow {
  display: flex;
  padding: 5px 0;
}

.iconColumn {
  img {
    height: 20px;
    width: auto;
  }
  min-width: 100px;
}

.checkBoxColumn {
  margin-right: 7px;
}

.line{
  border-bottom: rgba(255, 255, 255, 0.3) 1px solid;
  width: 100%;
  margin: 5px 0;
}
