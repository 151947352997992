.Portal {
  :global(.Drawer-appear), // :global here ensures the class names are not transformed
  :global(.Drawer-enter) {
      transform: translateX(-100%);
  }
  :global(.Drawer-appear-active),
  :global(.Drawer-enter-active) {
      transform: translateX(70px);
      transition: transform 0.3s ease-in-out;
  }
  :global(.Drawer-enter-done) {
      transform: translateX(70px);
      transition: transform 0.3s ease-in-out;
  }
  :global(.Drawer-exit-active),
  :global(.Drawer-exit) {
      transform: translateX(-100%);
      transition: transform 0.3s ease-in-out;
  }
}

.buttonWrap {
  position: fixed;
  top: 50px;
  left: 70px;
  z-index: 2;
}

.sidebarContent {
  padding-left: 20px;
  padding-right: 10px;
}
