.sort {
  position: relative;
  margin-left: 6px;
}

.activeSort {
}

.inactiveSort {
  color: #999999;
}
