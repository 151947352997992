.wrapper {
  .row {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    button {
      margin-right: 10px;
    }
  }
}
