@import "@blueprintjs/core/lib/scss/variables";
.tabsWrap {
    border: black 1px solid;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

.tabs {
    display: flex;
    justify-content: space-between;
    transition: 0.5s ease;
    margin: 12px 16px;
  }

  .buttonsWrap{
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    margin: 12px;
  }

  .button {
    cursor: pointer;
    transition: 0.7s ease;
    margin-left: 5px;
  }

  .buttonText{
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
  }

  .button:hover{
    background-color: none;
  }


  .bp4-button-text{
    background-color: aqua;
  }
