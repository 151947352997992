@import "../../../../globalStyles/variables";

.viewButtonsContainer {
  display: flex;
  justify-content: center;
}

/* Todo: finish this */
.activeCustomIconButton {
  background: yellow;
}

.customIconButton {
  @media (max-width: $breakpoint_medium_1) {
    .buttonText {
      display: none;
    }
    svg {
      margin-right: 0;
    }
  }
  &>span {
    padding: 0 10px;
    display: flex;
    align-items: center;
  }
  @media (min-width: $breakpoint_medium_1) {
    svg {
      margin-right: 6px;
    }
  }
}

