@import "../../../globalStyles/variables";

.container {
  color: #fff;
  border: 1px solid #5a6b85;
  border-radius: 4px;

  @include mapUiBlock;

  display: flex;
  flex-direction: column;
  align-items: stretch;
}
