.dashboardContainer {
  display: grid;
  grid-column-gap: 5px;
  grid-template-columns: 1fr 1fr;
}

.dashboardPlane {
  width: 190px;
  height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
}

.networkScore {
  color: #fff;
  background: #2c9d53;
}

.centerLineMiles {
  color: #303641;
  background: #cad9ef;
}

.value {
  font-size: 34px;
  margin-right: 8px;
}

.label {
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
}

