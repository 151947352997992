.root {
  height: 25px !important;
  padding: 0 15px;
  position: fixed !important;
  bottom: 0 !important;
  width: 100%;
  z-index: 100;
}

.content {
  max-width: 12000;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25px;
}

.footerRightBar {
  display: flex;
}

.help {
  white-space: nowrap;
  text-decoration: none;
}

.extraRightMargin {
  margin-right: 6px;
}

.help:hover {
  text-decoration: underline;
}
