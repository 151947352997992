@import '../../../globalStyles/zIndexes';

.container {
  position: absolute;
  left: 5px;
  bottom: 5px;
  z-index: $zIndexFinalAssetQaSettings;

  .divider {
    margin: 0 10px;
  }

  .editableStatus {
    margin: 0 10px;
    &.editable {
      color: green;
    }
    &.notEditable {
      color: red;
    }
  }
}

.floatingContainer {
  display: flex;
  align-items: center;
}
