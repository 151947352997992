@import "src/globalStyles/variables";


.module {
  width: 100%;
  display: grid;
  //grid-template-columns: var(--table-template);
  grid-template-columns: 200px 1fr 130px 130px 100px 50px;
  grid-column-gap: 20px;
  padding: 5px;
  margin-bottom: 3px;
}

.href,
.href:hover {
  text-decoration: none;
}

.tag {
  margin-right: 4px;
  margin-bottom: 2px;
}

.icon {
  margin-right: 3px;
}

.argument {
  margin-right: 4px;
  margin-bottom: 2px;
  max-width: 300px;
}

.argument:last-child {
  margin-bottom: 0;
}

.pipeline {
  margin-bottom: 10px;
}
.pipeline:hover {
  opacity: 0.9;
}
.gausslink, .sgausslink {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}
.gausslink {
  color: #aef;
}
.sgausslink {
  color: #eaf;
}
.assetslink{
  color: #4CAF50;
}
.listItemGridContainer {
  display: grid;
  grid-template-columns: 40px 1fr 80px 160px 90px 90px;
  column-gap: 10px;
}

.statusCell, .emailCell, .createdCell, .updatedCell {
  text-align: center;
}

@media (max-width: $breakpoint_medium_2) {
  .listItemGridContainer {
    display: grid;
    grid-template-columns: 40px 1fr 90px 90px;
    column-gap: 10px;
  }

  .emailCell {
    grid-column-start: 2;
    grid-column-end: 5;
    grid-row-start: 3;
    grid-row-end: 4;
  }

  .statusCell {
    //display: none;
    grid-column-start: 2;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 3;
    margin-bottom: 7px;
  }
}

@media (max-width: $breakpoint_small_2) {
  .listItemGridContainer {
    display: grid;
    grid-template-columns: 40px 1fr;
    column-gap: 10px;
  }

  .statusCell {
    grid-column-start: 2;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 3;
    margin-bottom: 7px;
  }

  .emailCell {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
    margin-bottom: 7px;
  }

  .createdCell {
    grid-column-start: 2;
    grid-column-end: 5;
    grid-row-start: 4;
    grid-row-end: 5;
    margin-bottom: 7px;
  }

  .updatedCell {
    grid-column-start: 2;
    grid-column-end: 5;
    grid-row-start: 5;
    grid-row-end: 6;
  }
}

.actionsButtonContainer {
  margin-bottom: 8px;
}

.status {
  width: 70px;
  text-align: center;
}

.copy {
  visibility: hidden;
  margin-left: 10px;
}

.pipelineContainer:hover .copy {
  visibility: visible;
}
.sessionFolderText:hover .copy {
  visibility: visible;
}

.edit {
  visibility: hidden;
  margin-left: 10px;
}

.pipelineContainer:hover .edit {
  visibility: visible;
}

.cross {
  margin-left: 10px;
}

.smalltick {
  margin-left: 10px;
}

.col {
  display: flex;
  align-items: center;
}

.colinputname {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
}

.tagWrap {
  margin-top: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: center;
}

.block {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-direction: column;
}

.tagLabel {
  margin-right: 10px;
}

.tag {
}

@media only screen and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 1) {
  .module {
    display: block;
  }

  .openButton {
    margin-right: 8px;
  }

  .collapse {
    margin-top: 8px;
  }

  .pipelineContainer {
    grid-template-columns: 1fr;
  }

  .argument-tag {
    margin-right: 4px;
    margin-bottom: 5px;
  }

  .row {
    margin-bottom: 10px;
  }

  .row:last-child {
    margin-bottom: 0;
  }
}

.filesWrapper {
  display: flex;
  margin-bottom: 20px;
}
.filesLabel {
  text-wrap: nowrap;
  margin-right: 10px;
}

.meta {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto;
  justify-content: start;
  grid-gap: 15px;
}

.metaItem {
  display: flex;
  margin-bottom: 3px;
  font-size: 14px;
}

.metaLabel {
  margin-right: 6px;
}

.geolocation {
  margin-bottom: 0 !important;
}

@media only screen and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 1) {
  .meta {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
}
