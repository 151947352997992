.logoWrapper {
  text-align: center;
  margin-bottom: 20px;
}

.urbiLogo {
  width: 140px;
  height: auto;
}

.form {
  z-index: 1;
  width: 300px;
  background: rgba(220, 224, 229, 0.8);
  backdrop-filter: blur(4px);
  border-radius: 10px;
  padding: 20px;
}

.logo {
  width: 200px;
  margin-bottom: 10px;
}

.title {
  text-align: center;
  font-size: 24px;
  font-weight: normal;
}

.error {
  margin-bottom: 10px;
  text-align: center;
}

.footer {
  text-align: center;
}

.forgetYourPassword {
  padding-top: 15px;
  font-size: 14px;
  text-align: center;
}

.signUpInstead {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  text-align: center;
}
