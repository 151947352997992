.formCt {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.form {
  z-index: 1;
  width: 300px;
  margin: auto;
  backdrop-filter: blur(4px);
  border-radius: 10px;
  padding: 20px;
}

.logo {
  width: 200px;
  margin-bottom: 10px;
}

.title {
  text-align: center;
  font-size: 24px;
  font-weight: normal;
}

.error {
  margin-bottom: 10px;
  text-align: center;
  color: #cc0000;
}

.footer {
  text-align: center;
}

.text {
  padding-top: 20px;
  font-size: 14px;
  text-align: center;
}
