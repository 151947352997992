$activeInputBorderColor: #08C2C3;
$inputBorderColor: #303333;
$buttonColor: #FF7C02;

[class~=bp4-dark] {

  .formContainer {
    display: grid;
    grid-row-gap: 1.1rem;

    label[class='bp4-label'] {
      font-size: 0.82rem;
      color: #959595; // Figure out how to make it work
    }

    button {
      &[class='bp4-button bp4-intent-primary'] {
        background: $buttonColor;
        font-size: 1.1rem;
        font-weight: bold;
        padding: 0.7rem;
        height: 2.8rem;

        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background: #d54305;
        }
      }

      &[class~=bp4-button][class*=bp4-intent-]:disabled,
      &[class~=bp4-button][class*=bp4-intent-][class=bp4-disabled] {
        font-size: 1.1rem;
        height: 2.8rem;
        background: green;
        padding: 0.7rem;

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.signInHint {

}

.formHeader {
  font-size: 2.2em;
}

.formSubheader {
}

.noWrap {
  white-space: nowrap;
}

.error {
  margin-top: 10px;
}

.formInput {
  border: none;
  border-bottom: 1px solid $inputBorderColor;
  padding: 0 0 7px 0;
  //margin: 0 0 5px 0;
  background: none;
  color: #fff;
  font-size: 1rem;

  width: 100%;

  &:focus {
    outline: none;
    border-color: $activeInputBorderColor;
  }
}
