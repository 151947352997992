.dialogContainer {
  position: relative;
}

[class~='bp4-button'].button {
  display: flex;
  justify-content: left;
}

.dialogHeader {
  position: relative;
}

.dialogContent {
  position: relative;
  padding: 8px;
}

.body {
  margin-bottom: 12px;
}

.closeButton {
  //position: absolute;
  //right: 0;
  //top: 0;
}

//.button {
//  margin-right: 8px;
//}
