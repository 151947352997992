.icon {
  vertical-align: text-bottom;
  margin-left: -2px;
  margin-right: 5px;
}

.plusIcon,
.sessionIcon,
.sessionOnMapIcon,
.pciMapIcon {
  vertical-align: middle;
  display: inline-block;
}

.plusIconWithMargin,
.sessionIconWithMargin,
.sessionOnMapIconWithMargin,
.pciMapIconWithMargin {
  @extend .pciMapIcon;
  margin-right: 8px;
}
