.page {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 58px 20px 20px;
  display: flex;
  flex-direction: column;
}

.content {
  margin-top: 22px;
}
