.error {
  margin-top: 8px;
}

.textarea {
  height: 56px;
  width: 300px;
}
.textarea::placeholder {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}
