.container {
  padding: 8px 0;

  .header {
    padding-bottom: 8px;
    //font-weight: bold;
  }

  .listContainer {
    max-height: 250px;
    overflow-y: scroll;

    .listItem {
      padding: 2px 0;
    }
  }
}
