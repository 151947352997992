.appSidebar {
  width: 60px;
  box-sizing: border-box;
  background-color: #1F232A;
}

/* Logo */

.logo {
  padding: 15px;
  border-bottom: 1px solid #0D0E1077;
  a {
    border: none;
    text-decoration: none;
  }
}

.logoImage {
  width: 30px;
  height: auto;
}

.separator {
  border-top: 1px solid #0D0E1077;
}

/* Buttons */

.appSidebarActionsContainer {
  padding: 15px;
}

.appSidebarGroup {
  margin-bottom: 8px;
  overflow: hidden;

  &:last-of-type {
    margin-bottom: 16px;
  }
  background: #2F3541;
  border-radius: 4px;
}

.appSidebarGroupSection {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #c4d0df;

  &:hover {
    background: #576982;
  }
}

span[class~=bp4-popover2-target].tooltipTarget {
  display: flex;
  align-items: center;
  justify-content: center;
}
