.map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.pciPopup {
  margin-top: 50px;
  padding-bottom: 0px !important;
}

.pciTooltip {
  position: absolute;
  pointer-events: none;
  z-index: 1;
  padding: 2px 5px;
  background: #383e47;
  color: #f6f7f9;
}

.svgText {
  font-size: 11px;
  fill: #99ff99;
}

.svgOuterText {
  font-size: 11px;
  stroke: #335555;
  stroke-width: 0.3em;
}

.circle {
  height: 12px;
  width: 12px;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
}
