@import '../../../globalStyles/zIndexes';

.container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.visibilityFiltersContainer {
  position: absolute;
  z-index: $zIndexFinalAssetQaVisibilityFilters;
  bottom: 61px;
  left: 5px;
  top: 8px;
}
