.profileSettingsModal {
  &[class~=bp4-dialog] {
    width: 80vw;
    max-width: 900px;
    padding: 0;
    position: relative;
    min-height: 70vh;
  }
}

.closeButton {
  position: absolute;
  z-index: 10;
  right: 5px;
  top: 5px;
}
