@import "src/globalStyles/variables";

.listHeaderGridContainer {
  display: grid;
  grid-template-columns: 40px 2fr 80px 160px 90px 90px;
  column-gap: 10px;
  padding: 20px;
}

.actionsCell {
  margin-left: -10px;
}

.statusCell, .emailCell, .createdCell, .distanceCell {
  text-align: center;
}

@media (max-width: $breakpoint_medium_2) {
  .listHeaderGridContainer {
    display: grid;
    grid-template-columns: 40px 1fr 90px 90px;
    column-gap: 10px;
  }

  .statusCell, .emailCell {
    display: none;
  }
}

@media (max-width: $breakpoint_small_2) {
  .listHeaderGridContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .statusCell, .emailCell, .sessionCell, .actionsCell {
    display: none;
  }
}

.headMobile {
  padding: 20px;
}

.headCol {
}

.distanceCell {
  min-width: 100px;
}
