@import '../../../globalStyles/variables';

.defaultPciFilterContainer {

}

.pciFiltersHead {
  padding: 10px 10px 0;
  display: grid;
  column-gap: 8px;
  font-weight: bold;
  font-size: 1.1em;

  &__twoTypes {
    @extend .pciFiltersHead;
    grid-template-columns: 50px 1fr 50px;
  }

  &__oneType {
    @extend .pciFiltersHead;
    grid-template-columns: 50px 1fr;
  }
}

.pciFiltersRow {
  display: grid;
  column-gap: 8px;

  &__twoTypes {
    @extend .pciFiltersRow;
    grid-template-columns: 1fr 30px 50px;
  }

  &__oneType {
    @extend .pciFiltersRow;
    grid-template-columns: 1fr 50px;
  }
}

.severitiesContainer {
  padding: 10px;
}

.extrasContainer {
  padding: 10px;
  background: rgba(0,0,0,0.2);
}

.filterRowsContainer {
  display: grid;
  row-gap: 8px;
}

.sampleUnitsColumn {
  text-align: right;
}

.pointsColumn {
  text-align: right;
}

.checkbox {
  margin-right: -8px;
}

[class~=bp4-control][class~=bp4-checkbox] {

  margin-bottom: 0;

  &.checkbox-good {
    [class=bp4-control-indicator] {
      background-color: $pciSeverityColorGood;
    }
    input:checked ~ [class~=bp4-control-indicator] {
      background-color: $pciSeverityColorGood;
    }
  }

  &.checkbox-satisfactory {
    [class=bp4-control-indicator] {
      background-color: $pciSeverityColorSatisfactory;
    }
    input:checked ~ [class~=bp4-control-indicator] {
      background-color: $pciSeverityColorSatisfactory;
    }
  }

  &.checkbox-fair {
    [class=bp4-control-indicator] {
      background-color: $pciSeverityColorFair;
    }
    input:checked ~ [class~=bp4-control-indicator] {
      background-color: $pciSeverityColorFair;
    }
  }

  &.checkbox-poor {
    [class=bp4-control-indicator] {
      background-color: $pciSeverityColorPoor;
    }
    input:checked ~ [class~=bp4-control-indicator] {
      background-color: $pciSeverityColorPoor;
    }
  }

  &.checkbox-inferior {
    [class=bp4-control-indicator] {
      background-color: $pciSeverityColorInferior;
    }
    input:checked ~ [class~=bp4-control-indicator] {
      background-color: $pciSeverityColorInferior;
    }
  }

  &.checkbox-serious {
    [class=bp4-control-indicator] {
      background-color: $pciSeverityColorSerious;
    }
    input:checked ~ [class~=bp4-control-indicator] {
      background-color: $pciSeverityColorSerious;
    }
  }

  &.checkbox-failed {
    [class=bp4-control-indicator] {
      background-color: $pciSeverityColorFailed;
    }
    input:checked ~ [class~=bp4-control-indicator] {
      background-color: $pciSeverityColorFailed;
    }
  }

  &.checkbox-target {
    [class=bp4-control-indicator] {
      background-color: #fff;
    }
    input:checked ~ [class~=bp4-control-indicator] {
      background-color: #eee;
      &::before {
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill-rule=%27evenodd%27 clip-rule=%27evenodd%27 d=%27M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z%27 fill=%27black%27/%3e%3c/svg%3e");
      }
    }
  }
}

.line{
  border-bottom: rgba(255, 255, 255, 0.3) 1px solid;
  width: 100%;
}
