@import '../../../../globalStyles/zIndexes';

.portal {
  background: green;
  z-index: 10000;
}

.pciViewerModal {
  &[class~=bp4-dialog] {
    width: 80vw;
    max-width: 900px;
    padding: 0;
    position: relative;
    z-index: $zIndexPciViewerModal;
  }
}

.closeButton {
  position: absolute;
  right: 10px;
  top: 8px;
  z-index: 1;
}
