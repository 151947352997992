.noWrap {
  white-space: nowrap;
}

.centered {
  text-align: center;
}

.break {
  margin-bottom: 10px;
}
