@import "../../globalStyles/zIndexes";

/* Empty but used class, do not delete! */
.stickyContainerPanel {
  &>:not(.stickyContainerPanel) {
    pointer-events: all;
  }
}

.stickyContainer {
  position: absolute; // Has to be inside RelativeContainer
  z-index: $zIndexStickyContainer;

  pointer-events: none;
  &>:not(.stickyContainerPanel) {
    pointer-events: all;
  }

  display: flex;

  &[class~='padded__md'] {
    padding: 8px;
  }

  &[class~='stickContent_fullLeft'] {
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;

    &>* {
      margin-right: 8px;
    }
  }

  &[class~='stickContent_topRight'] {
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;

    &>* {
      margin-bottom: 8px;
    }
  }

  &[class~='topRight'] {
    top: 0;
    right: 0;
  }

  &[class~='fullLeft'] {
    top: 0;
    left: 0;
    bottom: 0;
  }
}
