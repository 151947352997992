@import-normalize;

@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~@blueprintjs/popover2/lib/css/blueprint-popover2.css';
@import '~@blueprintjs/datetime/lib/css/blueprint-datetime.css';
@import '~@blueprintjs/table/lib/css/table.css';
@import '~mapbox-gl/dist/mapbox-gl.css';
@import '~react-toastify/dist/ReactToastify.css';
@import '~video.js/dist/video-js.css';
@import 'style/jsoneditor.css';

:root {
  --table-template: 240px 1fr 130px 130px 100px;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.bp4-dark {
  background: #1c2127;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  display: flex;
  width: 100%;
  min-height: 100vh;
}

.text-href {
  display: inline;
}
.bp4-dark .bp4-running-text h3 {
  margin-top: 0;
}

.panel {
  position: absolute;
  z-index: 2;
  top: 58px;
  left: 10px;
  width: 340px;
}

.logo {
  width: 158px;
  display: block;
  margin-bottom: 30px;
}

.mapboxgl-ctrl-attrib,
.mapboxgl-ctrl-bottom-left {
  display: none;
}

.bp4-html-select,
.bp4-select {
  width: 100%;
}

.fld {
  margin: 30px 0 0;
}

.card {
  width: 100%;
  margin-bottom: 5px;
}

.card-title {
  display: flex;
  justify-content: space-between;
}

.title {
  margin-top: 0;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.btn {
  margin-left: 10px;
}

.sub-title {
  margin-top: 0px;
  margin-bottom: 10px;
}

.module-card {
  padding: 5px;
  margin-bottom: 5px;
}

.module-card-header {
  padding: 5px;
  margin-bottom: 8px;
}

.module-card-header-row {
  width: 100%;
  display: grid;
  grid-template-columns: var(--table-template);
}

.module-card-body {
  width: 100%;
  display: grid;
  grid-template-columns: var(--table-template);
}

.module-output {
  margin-top: 5px;
}

.module-output-list {
  margin-top: 15px;
}

.module-link-list {
  margin-bottom: 15px;
}

.tag {
  margin-right: 4px;
  margin-bottom: 2px;
}

.output-tag {
  margin-right: 4px;
  margin-bottom: 2px;
  max-width: 300px;
}

.argument-tag {
  margin-right: 4px;
  margin-bottom: 2px;
  max-width: 300px;
}

.argument-tag:last-child {
  margin-bottom: 0;
}

.icon {
  margin-right: 3px;
}

.blank {
  text-decoration: none;
}

.blank:hover {
  text-decoration: none;
}

.box {
  margin-bottom: 5px;
}

.bp4-button {
  outline: none;
}

.color-switch {
  display: flex;
  align-items: center;
}

.poses-checkbox {
  margin: 24px auto;
}

.inline-spinner {
  display: inline-flex;
  margin-left: 8px;
}

.color-box {
  display: flex;
}

.color {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  margin-top: 2px;
}

.color-label {
  margin-left: 8px;
  height: 16px;
  line-height: 16px;
}

.color-switch .bp4-control-indicator {
  margin-top: 1px;
}

.error {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
}
.error .btn {
  margin-top: 20px;
}

.logo-href {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.logo-href-img {
  width: 158px;
}

.map-sidebar-title {
  margin-bottom: 10px;
  display: flex;
}

.text {
  margin-bottom: 2px;
}

.map-output-tag {
  display: inline-block;
  margin-bottom: 4px;
  margin-left: 3px;
}

@media only screen and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .module-card-header-row {
    width: 100%;
    display: block;
  }

  .module-card-body {
    width: 100%;
    display: block;
  }
  .argument-tag {
    margin-right: 4px;
    margin-bottom: 5px;
  }
  .module-card-header-item,
  .module-card-body-row {
    margin-bottom: 10px;
  }
  .module-card-header-item:last-child,
  .module-card-body-row:last-child {
    margin-bottom: 0;
  }
  .output-tag {
    display: inline-block;
    margin-bottom: 5px;
  }
  .list-header {
    flex-direction: column;
    margin-bottom: 15px;
  }
  .list-header-logo {
    margin-bottom: 15px;
  }
  .list-actions {
    display: block;
  }
  .list-actions-col {
    margin-bottom: 10px;
  }
  .list-actions-col:last-child {
    margin-bottom: 0px;
  }
}

.jsoneditor-frame input {
  background-color: #fff;
}

.bp4-toast-container {
  position: fixed !important;
}

.video-js .vjs-current-time,
.video-js .vjs-remaining-time {
  display: block !important;
}

.video-js .vjs-picture-in-picture-control {
  display: none;
}

.video-js.vjs-layout-small .vjs-playback-rate {
  display: block;
}

.video-js.vjs-fluid,
.video-js.vjs-16-9,
.video-js.vjs-4-3,
video.video-js,
video.vjs-tech {
  position: relative !important;
  width: 100%;
  height: auto !important;
  max-width: 100% !important;
  padding-top: 0 !important;
  line-height: 0;
}

/* Fix the control bar due to us resetting the line-height on the video-js */
.vjs-control-bar {
  line-height: 1;
}

.bp4-drawer-header {
  min-height: 50px;
}

.bp4-overlay.bp4-overlay-container {
  position: initial;
}

.bp4-popover2-content {
  padding: 5px !important;
}

a.href {
  color: #5f6b7c !important;
  text-decoration: underline !important;
}
.bp4-drawer {
  margin-top: 49px !important;
  margin-bottom: 24px !important;
}

.vjs-error .vjs-error-display:before {
  display: none;
}

.vjs-error-display .vjs-modal-dialog-content {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.bp4-tab {
  outline: none;
}

.bp4-tab-panel {
  margin-top: 5px;
}

.bp4-control.bp4-switch {
  margin-bottom: 0 !important;
  outline: none !important;
}

.bp4-navbar.bp4-fixed-top {
  z-index: 1000;
}

.mapboxgl-ctrl-scale {
  margin-right: 50px !important;
}

.bp4-dialog-container {
  /* padding: 8px; Todo: finish this (mobile friendly padding for dialogs) */
}

.toast-container {
  z-index: 999999;
}
