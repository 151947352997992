.container{
  display: flex;
  flex-direction: row;
}

.label{
  margin: 10px 0 5px;
}

.pickerContainer{
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}
.picker{
  width: 150px;
  height: 30px;
  padding: 5px 10px;
  margin-right: 5px;
  background-color: #383e47;
  border-radius: 2px;
  cursor: pointer;
  font-size: 14px;
  justify-content: center;
  border: none;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1),0 1px 2px rgba(17, 20, 24, 0.2);
}

.picker:hover{
  background-color: #2f343c;
}


