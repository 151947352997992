.paperContainer {
  padding: 15px;
  box-sizing: border-box;
  min-width: 300px;
}

.error {
  margin-top: 10px;
}

.extraMarginBottom {
  margin-bottom: 15px;
}

.centeredBlock {
  text-align: center;
}

.logo {
  width: 180px;
  margin-bottom: 25px;
}
