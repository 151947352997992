@import "../../globalStyles/variables";
@import "../../globalStyles/zIndexes";

.viewportsList {
  position: relative;

  padding: 5px;

  @include mapUiBlock;
}

.listItem {
  cursor: pointer;
  padding: 0 7px;
  display: flex;
  align-items: center;
  border-radius: 2px;

  &:hover {
    background: $buttonHoverColor;
  }

  .listItemName {
    padding: 2px 7px;
    flex-grow: 2;
    min-width: 100px;
  }
}
//
//.addButtonContainer {
//  margin-top: 10px
//}

.addDialog {
  max-width: 250px;
}

.addDialogContent {
  padding: 15px 15px;

  input {
    width: 100%;
    margin: 15px 0;
  }
  button {
    width: 100%;
  }
}
