$breakpoint_default: 0px;
$breakpoint_small_2: 476px;
$breakpoint_medium_1: 672px;
$breakpoint_medium_2: 780px;
$breakpoint_medium_3: 880px;
$breakpoint_large_from: 990px;
$breakpoint_x_large_from: 1296px;

$pciSeverityColorGood:         rgb(72, 151, 88);
$pciSeverityColorSatisfactory: rgb(137, 196, 88);
$pciSeverityColorFair:         rgb(235, 202, 98);
$pciSeverityColorPoor:         rgb(230, 157, 89);
$pciSeverityColorInferior:     rgb(218, 97, 93);
$pciSeverityColorSerious:      rgb(173, 54, 49);
$pciSeverityColorFailed:       rgb(154, 25, 20);

@mixin mapUiBlock {
  color: #fff;

  border: 1px solid #5a6b85;
  border-radius: 4px;

  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);

  background: rgba(21, 27, 38, 0.8);
}

$buttonHoverColor: rgba(143, 153, 168, 0.15);
