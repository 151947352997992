.selectorPopoverContent {
    max-height: 300px;
    overflow: scroll;
    max-width: 520px;
  }

.select {
    width: 150px;
  }
  
.select span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
  