.buttonGroup {
  &:not(:last-of-type) {
    margin-right: 6px;
  }

  overflow: hidden;

  color: #fff;
  border: 1px solid #5a6b85;
  border-radius: 8px;
  background-color: #151b267d;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);

  [class='bp4-button-text'] {
    color: #fff;
  }

  [class=bp4-button] > * {
    color: #fff;
  }

  button {
    border-radius: 0;
  }
}

.activeButton {
  background: rgba(0, 0, 0, 0.45);
}
