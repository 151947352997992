.tabsWrap {
  border: black 1px solid;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.tab {
  transition: 0.5s ease;
}

.buttonTabs {
  margin: 0;
  display: flex;
  flex-direction: row;
}

.buttonWithImageWrap {
  margin: 0;
  padding: 16px 12px;
  border-left: black 1px solid;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.image {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.button {
  font-size: 14px;
  line-height: 18px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: 0.7s ease;
}

.button:hover {
  color: #f2994a;
}

.email {
  font-size: 14px;
  line-height: 18px;
  margin: 0 12px 0 0;
}

.caret {
  width: 20px;
  height: 20px;
  transition: 0.7s ease;
  cursor: pointer;
}

.caret:hover {
  transform: rotate(180deg);
}

@media only screen and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 1) {
  .button {
    margin-bottom: 20px;
  }
}
