.loader {
  width: 100%;
  flex: 1;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.logo {
  width: 158px;
  padding: 20px;
}
.progress {
  width: 200px;
}
