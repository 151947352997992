.logo {
  height: 25px;
  outline: none;
}

.logoHref {
  outline: none;
  display: flex;
}

.user {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}

.avatar {
  position: relative;
  display: flex;
  width: 30px;
  height: 30px;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  flex-direction: row;
  align-items: center;
}

.logoWrap {
  display: flex;
  align-items: center;
}

.logoWrapIcon {
  margin-right: 10px;
  cursor: pointer;
}

.avatarImg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.avatarText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 15px;
  text-transform: uppercase;
  color: #1c2127;
  margin-top: -1px;
  visibility: hidden;
}

.info {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-bottom: 5px;
}

.organization_name {
  color: #abb3bf;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.2858142857;
}

.emailContainer {
  display: flex;
}

.email {
  margin-right: 3px;
}
.right {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.theme {
  display: flex;
  height: 30px;
  width: 100%;
  margin-top: 10px;
  align-items: center;
}

.themeSwitch {
  margin-bottom: 0 !important;
  margin-left: 10px !important;
}
