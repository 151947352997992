.detailsContainer {
  display: flex;
}

.detailsItem {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 7px;
}

.space {
  width: 50px;
}
