.section {
  padding: 17px;
}

.sectionHeader {
  font-size: 1.25em;
  margin-bottom: 15px;
}

.sectionText {
  margin-bottom: 8px;
}

.separator {
  margin: 16px 0;
  border-top: 1px solid rgba(0,0,0,0.3);
  width: 100%;
  height: 0;
}

.narrowFormContainer {
  width: 300px;
}
