:root {
  --bg-color: #30343b;
  --hover-color: #3f4b5b;
  --text-color: #fff;
  --odd-cell-color: #293747;
  --border-color: #ccc;
  --selection-bg-color: rgba(255, 255, 255, 0.1);
  --selection-border-color: rgba(255, 255, 255, 0.3);
  --header-bg-color: #1e2329;
}

// TODO: Clean up the styles, there are definitely some unneeded styles that can be removed

.cell,
.oddCell {
  padding: 0 2px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: var(--text-color) !important;

  &:hover {
    background-color: var(--hover-color);
  }
}

.cell {
  background-color: var(--bg-color);
}

.oddCell {
  background-color: var(--odd-cell-color);
}

.headerCell {
  background-color: var(--header-bg-color);
  color: var(--text-color) !important;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 30px;

  &:hover {
    background-color: var(--hover-color);
  }
}

.rowHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--header-bg-color);
  color: var(--text-color) !important;

  &:hover {
    background-color: var(--hover-color) !important;
  }
}

.table {
  border: 1px solid var(--border-color);
  border-radius: 4px;
  overflow: hidden;
  background-color: var(--bg-color);
  color: var(--text-color);

  :global {
    .bp5-table-container,
    .bp5-table-quadrant,
    .bp5-table-quadrant-scroll-container,
    .bp5-table-top-container,
    .bp5-table-bottom-container,
    .bp5-table-column-headers,
    .bp5-table-row-headers,
    .bp5-table-body-virtual-client {
      background-color: var(--bg-color);
    }

    .bp5-table-menu {
      background-color: var(--header-bg-color);
      color: var(--text-color) !important;
    }

    .bp5-table-cell,
    .bp5-table-column-header-cell,
    .bp5-table-header,
    .bp5-table-header-content,
    .bp5-table-column-name,
    .bp5-table-row-name {
      background-color: var(--header-bg-color);
      color: var(--text-color) !important;

      &:hover {
        background-color: var(--hover-color) !important;
      }
    }

    .bp5-table-header.bp5-table-last-in-column {
      box-shadow: none;
    }

    .bp5-table-selection-region {
      background-color: var(--selection-bg-color);
      border-color: var(--selection-border-color);
    }

    .bp5-table-row-headers {
      .bp5-table-header {
        background-color: var(--header-bg-color);
        color: var(--text-color) !important;

        &:hover {
          background-color: var(--hover-color) !important;
        }
      }
    }

    .bp5-table-cell-loading,
    .bp5-table-body-cells,
    .bp5-table-cell-client,
    .bp5-table-null {
      background-color: var(--bg-color) !important;
    }
  }
}

[class~='bp4-dialog'].dialog {
  width: 90vw;
  max-height: 90vh;
  background-color: var(--bg-color);
  color: var(--text-color);
}

.dialogContainer {
  position: relative;
  background-color: var(--bg-color);
  color: var(--text-color);
}
