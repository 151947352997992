:root {
  --ivites-list-template: 150px 150px 200px 1fr 80px 130px;
  --ivites-list-template-mobile: 150px 150px 200px 250px 80px 130px;
}

.wrap {
  overflow-x: scroll;
}

.invitesList {
  width: 100%;
  display: grid;
  grid-template-columns: var(--ivites-list-template);
  padding: 5px;
  margin-bottom: 3px;
}

.copy {
  cursor: pointer;
}

@media only screen and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 1) {
  .invitesList {
    grid-template-columns: var(--ivites-list-template-mobile);
  }
}
