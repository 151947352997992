.logo {
  width: 158px;
}

.wrap {
  width: 100%;
  padding: 20px;
  margin-top: 58px;
}

.form {
  max-width: 1200px;
  margin: 0 auto;
}
