.logoWrapper {
  text-align: center;
  margin-bottom: 20px;
}

.outer {
  display: flex;
  flex: 1;
  flex-direction: column;
  background: rgba(220, 224, 229, 0.8);
}

.inner {
  height: 100%;
  display: flex;
  flex: 1;
}

.logo {
  width: 200px;
  margin-bottom: 10px;
}

.title {
  text-align: center;
  font-size: 24px;
  font-weight: normal;
}

.formCt {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: auto;
}
