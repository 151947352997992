.changeStatusContainer {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 8px;
}

.descriptionInput {
  position: relative;
  padding-left: 8px;
}
