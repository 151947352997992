@import '../../../globalStyles/zIndexes';

.container {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: $zIndexFinalAssetQaPictureViewer;

  // Allow click-through:
  pointer-events: none;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  img {
    width: 100%;
    height: auto;
    margin-bottom: 8px;

    &.small {
      width: 600px;
      max-width: 50vw;
    }

    &.big {
      width: 900px;
      max-width: 80vw;
    }
  }
}

.detailsContainer {
  background-color: #151b26;
  padding: 6px 10px;
}

.twoColumnsContainer {
  pointer-events: auto;
  display: flex;
  align-items: flex-start;
}

.videoContainer {
  max-width: 400px;
}

.label {
  margin-top: 10px;
}
