.form {
  z-index: 1;
  width: 300px;
  background: RGBA(197, 203, 211, 0.8);
  backdrop-filter: blur(4px);
  border-radius: 10px;
  padding: 20px;
}

.logoWrapper {
  text-align: center;
  margin-bottom: 20px;
}

.logo {
  width: 200px;
  margin-bottom: 10px;
}

.title {
  text-align: center;
  font-size: 24px;
  font-weight: normal;
}

.footer {
  text-align: center;
}

.linkToSignIn {
  margin-top: 20px;
  text-align: center;
}

.error {
  text-align: center;
  padding-bottom: 15px;
}

.text {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 14px;
}
