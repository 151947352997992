.pciViewerContainer {
  position: relative;
  padding: 10px;
  min-height: 200px;
}

.topLine {
  //display: grid;
  //grid-template-columns: 1fr 1fr 1fr;
  //margin-bottom: 10px;
  display: flex;
  justify-content: center;
  padding-bottom: 5px;
  margin-bottom: 5px;
  font-size: 1.2em;

  .pciNumberContainer {
    display: flex;
    align-items: center;
  }
}

.viewportBlock {
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: 4px;
  position: relative;

}

.commonButtonsContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .navigationButtonsContainer {
    //
  }

  .viewModeButtonContainer {
    display: flex;
    &>button {
      margin-left: 8px;

    }
  }
}

.details {
}

.loadingContainer {
  min-height: inherit;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.headerPlaceholder {}

.errorMessage {
  color: #ff6b6b; // A softer red that works well in dark themes
  font-weight: bold;
  text-align: center;
  padding: 20px;
}

.copyableCode {
  cursor: pointer;
  font-family: monospace;
  background-color: #2a2a2a;
  padding: 2px 4px;
  border-radius: 3px;
  border: 1px solid #444;
  color: #e0e0e0;
}

.copyableCode:hover {
  background-color: #3a3a3a;
}
