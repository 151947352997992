@import "../../globalStyles/zIndexes";

.loadingOverlayContainer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0,0,0,0.2);
  z-index: $zIndexLoadingOverlay;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
}

.labelContainer {
  margin-bottom: 20px;
}

.progressBarContainer {
  width: 200px;
  overflow: hidden;
}
