@import "../../../globalStyles/zIndexes";

.demoUserPciPanelContainer {
  //position: absolute;
  //right: 10px;
  //top: 10px;
  //z-index: $zIndexPciDemoUserPciPanel;
}

.tryRoadlyProButton {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 50px;
  width: 200px;
  background: #ec913c;
  font-size: 1.3em;
  color: white;
  padding: 0 20px;
  border: none;
  border-radius: 5px;

  cursor: pointer;

  &:hover {
    background: #d57b29;
  }

  [class~=bp4-icon] {
    margin-right: 5px;
  }
}
