.assetFrameViewerModal {
  &[class~=bp4-dialog] {
    width: 80vw;
    max-width: 900px;
    padding: 0;
    position: relative;
  }
}

.closeButton {
  position: absolute;
  right: 3px;
  top: 3px;
}


.wrapper {

}

.header {
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  padding: 0 8px;
  font-size: 1.2em;
}

.imageWrapper {
  transition: all 400ms ease-in-out;
  min-height: 300px;
  position: relative;

  img {
    width: 100%;
  }
}

.navigationWrapper {

}

.navigation {

}

.loadingWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}
