.viewportContainer {
  position: relative;
  width: 100%;
}

.downloadButton {
  cursor: pointer;
}

.viewportImage {
  display: block;
  width: 100%;
  height: auto;
  min-height: 100px;

  transition: all 400ms;
}

.viewportMaskImage {
  opacity: 0.6;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  min-height: 100px;

  transition: all 400ms;

  position: absolute;
  z-index: 10;
}

.viewportRoiImage {
  @extend .viewportMaskImage;
  opacity: 0.6;
  z-index: 11;
}

.iconButtonsContainer {
  position: absolute;
  top: 10px;
  right: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
  z-index: 20;

  svg {
    display: block;
  }
}
