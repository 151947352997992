.formError {
  color: crimson;
}

.formItem {
  margin: 20px 0;
}

.collapsibleFormItem {
  margin-left: -5px;
}

.pathForm {
  width: 286px;
}

.pathFormWide {
  min-width: 286px;
  max-width: 750px;
}

.compactFormGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  :global(.bp4-form-content) {
    display: flex;
  }

  &.sessionSelect {
    :global(.bp4-form-content) {
      width: 100%;
    }
  }
}

.buttonText {
  margin: 0;
}

.headerButton {
  padding: 0 !important;
}

.inputShort {
  display: flex;
  max-width: 100px;
}
.inputWide {
  max-width: 170px;
}

.collapse {
  margin: 10px 0 40px 0;
}

.tripleCheckbox {
  margin-bottom: 10px;
}

.tripleCheckboxUnchanged {
  color: #bbc !important;
}

.tripleCheckoxReset {
  margin-left: 10px;
}
.paramDependent {
  &.inactive {
    pointer-events: None;
    opacity: 0.5;
  }
}

.warning {
  padding: 10px 0;
  font-size: 10px;
  color: #fcc;
}
.gaussOptions {
  padding-left: 30px;
}
