.info {
  z-index: 100;
  position: absolute;
  top: 10px;
  left: 15px;
}

.canvas {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  touch-action: none;
}

.instructions {
  background: rgba(0, 0, 0, 0.6);
  white-space: pre-wrap;
  padding: 10px;
  border-radius: 10px;
  font-size: x-small;
}

.small {
  color: #ccc;
}

.asmall {
  color: #77c;
}

.controls {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 10px 0;
}

.controlsRow {
  margin-bottom: 7px;
}

.keepPoseLabel {
  margin: 8px 0;
}

.keepPoseCheckbox {
  margin-right: 4px;
}
.posesSlider {
  margin-top:25px;
}