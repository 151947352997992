.header {
  display: flex;
  flex-direction: column;
}

.logo {
  width: 158px;
}

.selectorPopoverContent {
  max-height: 300px;
  overflow: scroll;
  max-width: 520px;
}

.filters {
  display: flex;
  align-items: center;
}

.col {
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-top: 10px;
}

.col:last-child {
  margin-right: 0;
}

.label {
  margin-right: 10px;
}

.title {
  font-size: 16px;
  font-weight: bold;
  margin-right: 15px;
}

.filterItems {
  display: flex;
  flex-wrap: wrap;
}

.flag{
  margin-top: 10px;
  margin-right: 20px;
}
@media only screen and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 1) {
  .header {
    flex-direction: column;
    margin-bottom: 10px;
  }
  .filters {
    flex-direction: column;
    align-items: flex-start;
  }
  .title {
    margin-bottom: 10px;
  }
  .filterItems {
    display: flex;
    flex-direction: column;
  }
  .actions {
    flex-direction: column;
    align-items: center;
  }
  .col {
    margin-bottom: 10px;
  }
}
.run_pipeline_wrap {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  margin: auto 20px;
}

.select {
  width: 150px;
}

.select span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.header {
  display: flex;
  flex-direction: column;
}

.filters {
  display: flex;
  align-items: center;
}

.col {
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-top: 10px;
}

.col:last-child {
  margin-right: 0;
}

.label {
  margin-right: 10px;
}

.title {
  font-size: 16px;
  font-weight: bold;
  margin-right: 15px;
}

.filterItems {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 1) {
  .header {
    flex-direction: column;
    margin-bottom: 10px;
  }
  .filters {
    flex-direction: column;
    align-items: flex-start;
  }
  .title {
    margin-bottom: 10px;
  }
  .filterItems {
    display: flex;
    flex-direction: column;
  }
  .actions {
    flex-direction: column;
    align-items: center;
  }
  .col {
    margin-bottom: 10px;
  }
}
