.authorizedLayoutWithAppSidebar {
  width: 100vw;
  min-height: 100vh;

  display: flex;
  align-items: flex-start;
}

.layoutSidebar {
  align-self: stretch;

  flex-grow: 0;
  flex-shrink: 0;
}

.layoutContent {
  flex-shrink: 2;
  flex-grow: 2;
  align-self: stretch;
  position: relative;
}
