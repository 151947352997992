@import "src/globalStyles/variables";

.button {
  cursor: pointer;
  transition: 0.5s ease;
}

.module {
  width: 100%;
  display: grid;
  grid-template-columns: var(--table-template);
  padding: 5px;
  margin-bottom: 3px;
}

.href,
.href:hover {
  text-decoration: none;
}

.tag {
  margin-right: 4px;
  margin-bottom: 2px;
}

.icon {
  margin-right: 3px;
}

.argument {
  margin-right: 4px;
  margin-bottom: 2px;
  max-width: 300px;
}

.argument:last-child {
  margin-bottom: 0;
}

.session {
  margin-bottom: 10px;
}

.session:hover {
  opacity: 0.9;
}

.listItemGridContainer {
  display: grid;
  grid-template-columns: 40px 2fr 80px 160px 90px 90px;
  column-gap: 10px;
  padding: 5px;
}


.img {
  width: 100%;
  height: calc(100% - 33px);
  margin-top: 33px;
}

.container {
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr 400px 400px;
  column-gap: 10px;
  //max-width:600px;
}

.videoContainer {
  height: 258px;
}

.videoPlayerContainer {
  height: 100px;
}

.pipelines {
  width: 100%;
  margin-top: 15px;
}

.runButtons {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.runButton {
  max-width: 100px;
  height: 32px;
  background-color: #2F80ED;
  border: none;
  border-radius: 4px;
  padding: 8px 10px;
  font-size: 12px;
  line-height: 16px;
  outline: none;
  cursor: pointer;
  text-wrap: nowrap;
}

.runButton:hover {
  background: #1C5EB8;
  transition: ease-in 0.3s;
}


.runButton:disabled {
  cursor: auto;
  color: white;
  background: #1F232A;
;
}

.assetsTextInput {
  background: #1c2127;
  border: none;
  color: #f6f7f9;
}

.assetsTextInput:focus{
  outline: none;
}

.sessionIdCell, .emailCell, .createdCell, .distanceCell {
  text-align: center;
}

@media (max-width: $breakpoint_medium_2) {
  .listItemGridContainer {
    display: grid;
    grid-template-columns: 40px 1fr 90px 90px;
    column-gap: 10px;
  }

  .sessionIdCell, .emailCell {
    display: none;
  }
}

@media (max-width: $breakpoint_small_2) {
  .listItemGridContainer {
    display: flex;
    flex-direction: column;
  }

  .sessionIdCell, .emailCell, .sessionCell, .actionsCell {
    display: none;
  }

  .menuContainer {
    margin-bottom: 10px;
  }
}

.actionsButtonContainer {
  margin-bottom: 8px;
}

.status {
  width: 70px;
  text-align: center;
}

.copy {
  visibility: hidden;
  margin-left: 10px;
}

.pipelineContainer:hover .copy {
  visibility: visible;
}

.edit {
  visibility: hidden;
  margin-left: 10px;
}

.pipelineContainer:hover .edit {
  visibility: visible;
}

.cross {
  margin-left: 10px;
}

.smalltick {
  margin-left: 10px;
}

.col {
  display: flex;
  align-items: center;
}

.colinputname {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
}

.tagWrap {
  margin-top: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: center;
}

.block {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-direction: column;
}

.tagLabel {
  margin-right: 10px;
}

.tag {
}

@media only screen and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 1) {
  .module {
    display: block;
  }

  .openButton {
    margin-right: 8px;
  }

  .collapse {
    margin-top: 8px;
  }

  .pipelineContainer {
    grid-template-columns: 1fr;
  }

  .argument-tag {
    margin-right: 4px;
    margin-bottom: 5px;
  }

  .row {
    margin-bottom: 10px;
  }

  .row:last-child {
    margin-bottom: 0;
  }
}

.filesWrapper {
  display: flex;
  margin-top: 10px;
}

.filesLabel {
  flex: 80px;
  margin-right: 10px;
}

.meta {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: start;
  grid-gap: 10px;
}

.metaItem {
  display: flex;
  margin-bottom: 3px;
  font-size: 14px;
}

.metaLabel {
  margin-right: 10px;
}

.geolocation {
  margin-bottom: 0 !important;
}

@media only screen and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 1) {
  .meta {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
}


.videoUrl, .videoUrl:visited, .videoUrl:link {
  display: inline-block;
  color: white;
  text-decoration: none;
  text-align: left;
  font-size: 14px;
  margin: 0 0 15px;
  width: 100%;
}

.openButton {
  margin-right: 8px;
}

.menuContainer {
  display: flex;
  flex-direction: column;
}
