/*
 * This file is just for styling document container
 */

@import "src/globalStyles/variables";

.documentWrapper {
  width: 100%;
  box-sizing: border-box;
  margin: 50px auto;
  padding: 15px;

  @media (min-width: $breakpoint_medium_2) {
    max-width: 800px;
    margin: 80px auto;
  }
}
