.wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 25px;
  left: 0;

  .mapWrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    transition: left ease-in-out 400ms;

    &.reduced {
      left: 460px;
    }
  }

}


.loadingOverlay {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  position: absolute;
  background: rgba(0, 0, 0, 0.2);

  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
