.Portal {
    :global(.Drawer-appear), // :global here ensures the class names are not transformed
    :global(.Drawer-enter) {
        transform: translateX(-100%);
    }
    :global(.Drawer-appear-active),
    :global(.Drawer-enter-active) {
        transform: translateX(70px);
        transition: transform 0.3s ease-in-out;
    }
    :global(.Drawer-enter-done) {
        transform: translateX(70px);
        transition: transform 0.3s ease-in-out;
    }
    :global(.Drawer-exit-active),
    :global(.Drawer-exit) {
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
    }
}


.drawer {
    pointer-events: all;
}
.buttonWrap {
  position: fixed;
  top: 50px;
  left: 70px;
  z-index: 2;
}

.section {
}

.title {
  display: flex;
  font-weight: bold;
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.subtitle {
  display: flex;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.titleText {
  margin-right: 15px;
}

.severityCheckboxesWrap {
  display: flex;
}

.severityCheckboxWrap label span {
  box-shadow: inset 0 0 0 1px var(--severity-color) !important;
}

.severityCheckboxWrap input:checked ~ span {
  background-color: var(--severity-color) !important;
}

.severityCheckboxWrap input:focus ~ span {
  outline: none !important;
}

.exportPCILink span {
  font-size: 14px !important;
  font-weight: bold;
  text-decoration: none !important;
}
a.exportPCILink:hover {
  text-decoration: none;
}
.inputImage,
.inputVideo {
  width: 100%;
  border: 1px solid #ccc;
}
.inputLink {
  display: flex;
  justify-content: end;
}
.disabled {
  opacity: 0.4;
  pointer-events: none;
  cursor: pointer;
}
