.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;

  grid-column-gap: 8px;
  grid-row-gap: 8px;
}

.fullWidthContainer {
  grid-column-start: 1;
  grid-column-end: 3;
}

.assetSizesControlsWrapper {
  display: flex;
  align-items: center;
  width: 100%;

  input[type=text] {
    flex-grow: 2;
    margin-left: 8px;
  }
}

.formLabel {
  font-size: 12px;
  color: #C4D0DF;
  margin-bottom: 5px;
}

.formInput {
  background: #0D0E10;
  color: #70839E;
  border: 1px solid #2F3541;
  border-radius: 3px;
  height: 30px;
  line-height: 30px;
  padding: 0 8px;
}

.formSubmitButton {
  background: #2F354199;
  color: #70839E;
  border: none;
  border-radius: 3px;
  width: 100%;
  height: 30px;
  font-size: 1.05em;
}

.error {
  color: orange;
}

.submitButton {
  width: 100%;
  height: 30px;
}

.bringToBottom {
  //margin-top: 17px;
  //display: flex;
  //flex-direction: column;
  //justify-content: flex-end;
}
