.demoAutoLoginContainer {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.label {
  margin-bottom: 15px;
}
