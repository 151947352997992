@import "src/globalStyles/variables";

.image {
  width: 100%;
  object-fit: cover;
  border: 1px solid #000;
  border-radius: 4px;
  //margin-bottom: 20px;

  @media (min-width: $breakpoint_medium_1) {
    /**
     * Wide container is for wrapping tutorial used in page
     * Tutorial non wrapped with it is used in modal dialog
     */
    .wideContainer & {
      width: 500px;
      max-width: 100%;
      //margin: 20px auto;
      display: block;
    }
  }
}

.customList {
  list-style-type: none;
  padding-left: 15px;
}

.instructionGrid {
  //margin: 25px 0;
  width: 100%;

  .instructionRow {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .instructionCell {
      padding: 5px;
      box-sizing: border-box;

      &.instructionCellText {
        width: 35%;
      }

      &.instructionCellImage {

        width: 65%;
        display: flex;
        justify-content: center;
        align-items: center;

        .instructionImage {
          object-fit: cover;
          border: 1px solid #000;
          border-radius: 4px;

          max-width: 100%;
          max-height: 300px;
        }
      }
    }


    @mixin vertical-table {
      &:not(:last-of-type) {
        margin-bottom: 30px;
      }
      flex-direction: column;
      .instructionCell {
        &.instructionCellText, &.instructionCellImage {
          width: 100%;
        }
      }
    }

    @media (max-width: $breakpoint_medium_1) {
      @include vertical-table
    }

    .narrowContainer & {
      @include vertical-table
    }

  }
}

.imageContainer {
  padding: 15px;
  background: rgba(255, 255, 255, 0.07);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.extraMarginBottom {
  margin-bottom: 50px;
}
