@import "src/globalStyles/variables";


.signUpPaper {
  width: 1000px;
  height: 600px;

  display: grid;
  grid-template-columns: 360px 1fr;


  .signUpFormBlock {
    background: #1a1a1a;
    padding: 50px;
    justify-content: center;
    align-items: stretch;
    display: flex;
    flex-direction: column;
    position: relative;

    .signInHint {
      position: absolute;
      top: 20px;
      right: 25px;
    }

    .passwordRecoveryHint {
      margin-top: 1.1rem;
      text-align: center;
    }

    .successMessage {
      text-align: center;
    }
  }

  .noWrap {
    white-space: nowrap;
  }

  .break {
    margin-bottom: 10px;
  }

  .thankYou {
    font-size: 1.5em;
    margin-bottom: 20px;
  }

  .mapBlock {
    background: #1a1a1a;
  }

  @media (max-width: $breakpoint_large_from) {
    width: 100vw;
    max-width: 360px;
    grid-template-columns: 1fr;
    background: green;

    .mapBlock {
      display: none;
    }
  }
}
