.positionBar {
  position: relative;
  width: 100%;
  height: 20px;
}

.positionJitter {
  position: absolute;
  border-left: 2px solid white;
  padding-left: 4px;
  margin-left: -1px;
}
