@import "../../../globalStyles/zIndexes";

.miniPciContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  //bottom: 25px;
  left: 0;
}

.pciSeverityFilters {
  position: absolute;
  left: 10px;
  bottom: 50px;
  z-index: $zIndexPciSeverityFilters;
  color: #fff;
  border: 1px solid #5a6b85;
  border-radius: 8px;

  background-color: #151b267d;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.rightTopCornerContainer {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: $zIndexPciMenu; // Todo: rename

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;

  &>* {
    margin-bottom: 8px;
  }
}

.map {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.loadingContainer {
  position: absolute;
  top: 80px;
  left: 10px;
  width: 214px;
  z-index: $zIndexPciDataTypeFilters;
  overflow: hidden;
}

.pciDataTypeFilters {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: $zIndexPciDataTypeFilters;
}

.pciDashboardContainer {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: $zIndexPciDashboardContainer;
}

.gettingStartedHintContainer {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: $zIndexGettingStartedHint;
}
