.wrapper {
  //
}

.header {
  padding: 15px 8px;

  display: grid;
  grid-template-columns: 50px 1fr 70px;
  grid-column-gap: 8px;
}

.pipelineListItem {
  border-top: 1px solid #2F3541;
  padding: 15px 8px;

  display: grid;
  grid-template-columns: 50px 1fr 70px;
  grid-column-gap: 8px;

  &:hover {
    background: rgb(112 129 156 / 11%);
    cursor: pointer;
  }
}
