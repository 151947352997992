.floatingContainer {
  color: #fff;
  border: 1px solid #5a6b85;
  border-radius: 8px;

  padding: 10px;

  background-color: #151b267d;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);

  overflow-y: scroll;
  overflow-x: hidden;
}
