:root {
  --table-template: 240px 1fr 130px 130px 100px 30px;
}

.list {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 58px 20px 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.emptyButton {
  margin-top: 20px;
}
