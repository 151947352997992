.wrapper {
  width: 460px;
  transition: width ease-in-out 400ms;

  height: 100%;
  background: #1F232A;
  box-sizing: border-box;

  position: fixed;
  z-index: 5;
  bottom: 0;
  top: 0;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.sessionWrapper {
  flex-grow: 0;
  flex-shrink: 0;
}

.pipelinesWrapper {
  flex-grow: 1;
  overflow-y: scroll;
  padding-bottom: 150px;
}

.header {
  text-align: center;
  height: 35px;
  line-height: 35px;
  font-size: 18px;
  position: relative;
}

.closePanelButton {
  position: absolute;
  right: 2px;
  top: 2px;
  bottom: 2px;
}

.videoWrapper {
  background: #000;
  width: 100%;
}

.imageWrapper {
  background: #000;
  width: 100%;
  min-height: 240px;
  img {
    width: 100%;
    height: auto;
  }
}

.video {
  width: 100%;
  height: auto;
}

.formsContainer {
  background: #0D0E10;
  padding: 10px 15px;

  .tabsContainer {
    display: flex;
    justify-content: flex-end;
  }
}

.sessionDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: #70839E;
  padding: 10px 15px;

  border-top: 1px solid #2F3541;
  border-bottom: 1px solid #2F3541;
}

.assetsLegendWrapper {
  padding: 8px;
  justify-content: center;
  overflow-y: scroll;

  &>div {
    width: 322px;
    margin: 0 auto 30px;
  }
}

.assetsLegendHeader {
  padding: 8px 0 16px;
  margin-bottom: 16px;

  text-align: center;
  font-size: 1.1em;
  border-bottom: 1px solid #2F3541;
}

.assetsLegend {
  /*
  display: grid;
  grid-template-columns: 20px 1fr 50px;
  column-gap: 8px;
  row-gap: 8px;
  width: 332px;
  padding: 0 16px;
  */
}

.colourLegendPoint {
  width: 14px;
  height: 14px;
  border-radius: 7px;
}

.legendAmountColumn {
  text-align: right;
}
