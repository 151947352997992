$orange: #ec913c;
$backgroundColor: #ec913cf2;
$textColor: #fff;
$borderColor: rgba(0, 0, 0, 0.2);

$verticalPaddingSize: 10px;
$sidePaddingSize: 18px;

.hintContainer {
  background: $backgroundColor;
  //border-radius: 6px;
  width: 400px;
  min-height: 200px;
  color: $textColor;
  padding: $verticalPaddingSize $sidePaddingSize $sidePaddingSize;

  font-size: 1rem;

  display: grid;
  grid-template-rows: 1fr 25px;
  row-gap: 10px;

  [class~=bp4-dark] & a {
    color: #fff;
    text-decoration: none;
    border-bottom: 1px solid #fff;
    padding-bottom: 0;

    &:hover {
      padding-bottom: 1px;
      color: #fff;
    }
  }
}

.hint {
  margin-bottom: $verticalPaddingSize;

  .hintHeader {
    margin-right: 20px; // To avoid intersecting with close button
    font-size: 1.2rem;
  }

  .separator {
    border-bottom: $borderColor 1px solid;
    margin: $verticalPaddingSize 0;
  }

  .hintContent {
    font-size: 0.9rem;
  }
}

.closeButton {
  width: 25px;
  height: 25px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 12px;
  background: none;
  border: none;
  top: $verticalPaddingSize;
  cursor: pointer;
}

.pagination {
  display: flex;
  justify-content: left;

  .paginationButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;

    width: 25px;
    height: 25px;

    cursor: pointer;

    border-radius: 2px;
    background: $textColor;
    color: $orange;

    &:hover {
      background: #ffffffc2;
    }

    &:disabled {
      background: rgba(255, 255, 255, 0.5);
    }
  }

  .paginationLabel {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 3rem;
  }
}
