.rootContainer {
  margin-bottom: 10px;
  position: relative;
}

.controlsContainer {
  margin-bottom: 4px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.confirmDialogBody {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonsContainer {
  display: flex;
  justify-content: center;
}
