.settings {
  position: fixed;
  right: 10px;
  bottom: 32px;
  z-index: 100;
}

.block {
  margin-top: 12px;
}

.content {
  padding: 20px 20px 15px;
}

.section {
  margin-bottom: 30px;
}

.section:last-child {
  margin-bottom: 0px;
}

.title {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}

.titleText {
  margin-right: 10px;
}
