$zIndexLoadingOverlay: 1000;
$zIndexGettingStartedHint: 1020;

$zIndexPciViewerModal: 1010;

$zIndexFinalAssetQaVisibilityFilters: 500;
$zIndexFinalAssetQaControls: 500;
$zIndexFinalAssetQaSettings: 501;
$zIndexFinalAssetQaPictureViewer: 502;

$zIndexStoredViewportsList: 100;

$zIndexPciDemoUserPciPanel: 50;

$zIndexStickyContainer: 17;

$zIndexPciMenu: 15;
$zIndexPciDataTypeFilters: 11;
$zIndexPciDashboardContainer: 11;
$zIndexPciSeverityFilters: 10;
