.miniPlayer {
  position: fixed;
  top: 58px;
  right: 8px;
  width: 350px;
  height: 197px;
  z-index: 4;
}

.fullPlayer {
  display: flex;
  flex: 1;
  height: 100vh;
  justify-content: center;
  align-items: center;
  z-index: 3;
  fdirection: column;
  position: relative;
}

.fullPlayer .player {
  margin-top: 50px;
  max-height: calc(100vh - 50px);
  width: 100%;
}

.fullscreenButton {
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 2;
}

.hideButton {
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 2;
}

.showButton {
  position: absolute;
  top: 58px;
  right: 11px;
  z-index: 4;
}
