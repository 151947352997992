.popup {
  margin: -25px 0 0 25px;
}

.clusterFeaturesContainer {
  max-width: 102px;
}

.image {
  padding: 2px;
  height: 32px;
  width: auto;
  cursor: pointer;
}
